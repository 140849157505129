<template>
  <div style="background-color: rgb(245, 245, 247)">
    <div class="page">
      <div style="
          background-color: #f5f5f7;
          padding-top: 18px;
          padding-bottom: 30px;
        ">
        <el-row :gutter="10">
          <el-col :span="4">
            <div style="background-color: #ffffff">
              <el-row class="info">
                <el-upload :headers="{ 'Device-Type': 'pc', Token: token }" :data="{ type: 'img' }"
                  :show-file-list="false" :on-success="handleAvatarSuccess"
                  action="https://bkgaoshou.com/index.php/api/upload/uploadHeadImg">
                  <el-avatar v-if="userInfo.head_img" :size="50" :src="userInfo.head_img" class="avatar" />
                  <template v-else>
                    <img class="default_img" v-if="userInfo.is_sex == 1" src="@/assets/img/user_info/man.png" alt="" />
                    <img class="default_img" v-if="userInfo.is_sex == 0" src="@/assets/img/user_info/girl.png" alt="" />
                  </template>
                </el-upload>
                <p class="uname">{{ userInfo.user_name }}</p>
                <p class="year">高考年份:2025</p>
              </el-row>
              <div class="bg"></div>
              <router-link class="nav-list" active-class="act" to="/user/info">
                <span>个人信息</span>
              </router-link>
              <router-link class="nav-list" active-class="act" to="/user/attCollege">
                <span>关注院校</span>
              </router-link>
              <!-- <router-link class="nav-list" active-class="act" to="/user/attSpecialty">
                <span>关注专业</span>
              </router-link> -->
              <!-- <router-link class="nav-list" active-class="act" to="/user/couponInfo">
                <span>我的优惠券 </span>
              </router-link> -->
              <router-link class="nav-list" active-class="act" to="/user/myVolunteer">
                <span>我的志愿表</span>
              </router-link>
              <router-link class="nav-list" active-class="act" to="/user/testReport">
                <span>生涯测评 </span>
              </router-link>
              <router-link class="nav-list" active-class="act" to="/user/MyCard">
                <span> 绑定会员卡 </span>
              </router-link>
              <router-link class="nav-list" active-class="act" to="/user/down-zhuanke" v-if="formData.name">
                <span> 专科数据 </span>
              </router-link>
              <!-- <router-link class="nav-list" active-class="act" to="/user/review">
                <span> 方案审核 </span>
              </router-link> -->
              <router-link class="nav-list" active-class="act" to="/user/ChangePwd">
                <span>修改密码</span>
              </router-link>
              <div class="nav-list" @click="logout">
                <span>安全退出 </span>
              </div>
            </div>
          </el-col>
          <el-col :span="20">
            <router-view />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "Index",
  data() {
    return {
      token: localStorage.getItem("TOKEN"),
      formData: {
        name: ''
      }
    };
  },

  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    if (!this.token) {
      this.$store.commit("loginOut");
      localStorage.clear();
      this.$router.push("/Login");
    }
    this.getZhuanke()
  },
  methods: {
    ...mapMutations(["loginOut"]),
    logout() {
      this.$alert("您确认要退出吗！", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            localStorage.clear();
            this.loginOut();
            this.$router.push("/");
          }
        },
      });
    },
    handleAvatarSuccess(res) {
      if (res.code === "200") {
        this.userInfo.head_img = res.data;
      } else {
        this.$notify({
          showClose: true,
          message: "上传图片失败！",
          type: "error",
        });
      }
    },
    getZhuanke() {
      this.$fecth.post("down_zhuanke/getDataById").then((res) => {
        this.formData = res.data
      });
    }
  },
};
</script>

<style lang="less" scoped>
.default_img {
  width: 45px;
  position: relative;
  top: 8px;
}

.avatar {
  width: 45px;
  position: relative;
  top: 10px;
  margin-right: 5px;
}

.page {
  .info {
    text-align: center;
    padding: 20px 20px;

    p {
      line-height: 33px;
    }

    .uname {
      margin-top: 20px;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .year {
      font-size: 16px;
    }
  }

  .nav {
    display: block;
    color: #444444;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border-right: 1px solid #dedede;
    margin-bottom: 10px;

    &.none-b {
      border: none;
    }

    p {
      margin-bottom: 10px;
    }

    .my-score {
      color: red;
    }
  }

  .nav-list {
    display: block;
    color: #6c6c6c;
    padding: 10px 10px 10px 30px;
    border-bottom: 1px solid #f2f2f2;

    &.act {
      border-left: 5px solid #278bfa;
    }

    .iconfont {
      margin-right: 10px;
    }
  }
}

.bg {
  height: 10px;
  background: rgb(245, 245, 247);
}
</style>
